import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react"
import { FormattedMessage } from "react-intl";


const BasicInfo = () => {

  const data = useStaticQuery(graphql`
    query {
      icon1: file(relativePath: { eq: "lightbulb.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      },
      icon2: file(relativePath: { eq: "briefcase.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      },
      icon3: file(relativePath: { eq: "satisfaction.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  return (
    <section className="about" id="about">
      <div className="container">
        <div className="row" style={{padding: '10px'}}>
          <div className="col-12 col-lg-6 wow fadeInLeft" data-wow-delay="300ms" style={{marginBottom: '30px'}}>
            <p className="text-orange font-weight-200 font-20"><FormattedMessage id="info.basicInfo" defaultMessage="Basic Info about company" /></p>
            <h1 className="main-font font-weight-600 text-white"><FormattedMessage id="info.title" defaultMessage="We always keep in focus to develop StreamLINE solutions for our customers" /></h1>
          </div>

          <div className="col-12 col-lg-6 m-ipad wow fadeInRight" data-wow-delay="300ms">
            <div className="ml-md-4 pl-md-2 font-weight-200 text-grey font-18">
              <p><FormattedMessage id="info.company" defaultMessage="StreamLINE-Solutions is an IT company who develop and provide tailor made solutions to companies and individuals" /></p>
              <p><FormattedMessage id="info.team" defaultMessage="Our team is made up of specialists with extensive experience in financial and medical IT" /></p>
            </div>
          </div>
        </div>
        <div className="row about-margin">
          <div className="col-md-4 col-sm-12">
            <div className="about-box center-block bg-green wow zoomIn" data-wow-delay="400ms">
              <div className="about-main-icon pb-4">
                <GatsbyImage
                  image={data.icon1.childImageSharp.gatsbyImageData}
                  alt="lightbulb-icon" />
              </div>
              <h5><span><FormattedMessage id="info.ideas" defaultMessage="We always have some IT ideas for you" /></span></h5>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="about-box center-block bg-blue wow zoomIn" data-wow-delay="500ms">
              <div className="about-main-icon pb-4">
                <GatsbyImage
                  image={data.icon2.childImageSharp.gatsbyImageData}
                  alt="briefcase-icon" />
              </div>
              <h5><span><FormattedMessage id="info.business" defaultMessage="Your business is our top priority" /></span></h5>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="about-box center-block bg-green wow zoomIn" data-wow-delay="600ms">
              <div className="about-main-icon pb-4">
                <GatsbyImage
                  image={data.icon3.childImageSharp.gatsbyImageData}
                  alt="satisfaction-icon" />
              </div>
              <h5><span><FormattedMessage id="info.customers" defaultMessage="We love our valued customers" /></span></h5>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center wow bounceInLeft" data-wow-delay="300ms">
          <img src="corporate-agency/images/about-img.png" alt="About-Us" />
        </div> */}
      </div>
    </section>
  )
}

export default BasicInfo
