import * as React from "react"
import { FormattedMessage } from "react-intl"


const About = () => {

  return (
    <section className="portfolio" id="portfolio">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12 col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering text-green">01.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.developement" defaultMessage="Development of applications" /></h5>
                <p className="card-text info"><FormattedMessage id="about.developementDescription" defaultMessage="Development of applications specific to your business using the latest technologies." /></p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering">02.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.itManagement" defaultMessage="Management of IT platform" /></h5>
                <p className="card-text info"><FormattedMessage id="about.itManagementDescription" defaultMessage="Management of your complete IT platform (servers, firewalls, client-computers, etc.) on premise, cloud and hybrid." /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6  wow fadeInRight" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering">03.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.security" defaultMessage="Security audits and advices" /></h5>
                <p className="card-text info"><FormattedMessage id="about.securityDescription" defaultMessage="Security audits and advices." /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering text-green">04.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.dataIntegration" defaultMessage="Data integration and migration" /></h5>
                <p className="card-text info"><FormattedMessage id="about.dataIntegrationDescription" defaultMessage="Data integration and migration." /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default About
