import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { FormattedMessage } from "react-intl";


const Products = () => {

  const data = useStaticQuery(graphql`
  query {
    img1: file(relativePath: { eq: "cloud.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img2: file(relativePath: { eq: "transferFiles.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img3: file(relativePath: { eq: "209434461.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img4: file(relativePath: { eq: "Invoice-amico.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img5: file(relativePath: { eq: "data-extraction.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`);

  return (
    <section className="products" id="products">
      <div className="blog-content">
        <div className="container">
          <div className="col-12">
            <div className="standalone-detail">
              <div className="row no-gutters">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2  text-center wow slideInUp" data-wow-duration="2s">
                  <h1 className="heading text-white">
                    <span className="text-orange" style={{ marginRight: '10px' }}>
                      <FormattedMessage id="products.our" defaultMessage="Our" />
                    </span>
                    <FormattedMessage id="products.products" defaultMessage="Products" />
                  </h1>
                </div>
              </div>
            </div>

            <div className="standalone-area">
              <div className="row standalone-row align-items-center no-gutters">
                <div className="col-lg-6">
                  <div className=" wow hover-effect fadeInLeft image">
                    <GatsbyImage
                      className="img"
                      image={data.img1.childImageSharp.gatsbyImageData}
                      alt="product1-image" />
                  </div>
                </div>
                <div className="col-lg-6 stand-img-des">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamDesk" defaultMessage="StreamDesk" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.streamDeskTitle" defaultMessage="Your premium workspace in the cloud" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamDesk_1" defaultMessage="StreamDesk provides you a complete computer that you can access anywhere and anytime just by logging into your account." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamDesk_2" defaultMessage="Enterprise high grade technology associates your machine(s) with our data centers located in Switzerland." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamDesk_3" defaultMessage="Our goal is simple: to bring you the same or better experience than a conventional computer, by transferring the power of our data centers to your devices. All the transmissions are encrypted and redundant backups ensure optimal security of your data." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamDesk_4" defaultMessage="StreamDesk is compatible with all your currents devices, just by installing an additional security layer that allow the communication with ours servers." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamDesk_5" defaultMessage="We also propose netbooks and thin clients preinstalled with our own Linux based operating system “StreamOS” which ensure the best level of experience." /></p>
                  </div>
                </div>
              </div>

              <div className="row standalone-row align-items-center no-gutters display-reverse">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInRight text-center image">
                    <GatsbyImage
                      className="img"
                      image={data.img2.childImageSharp.gatsbyImageData}
                      alt="briefcase-icon" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamLab" defaultMessage="StreamLAB" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.streamLabTitle" defaultMessage="Secure two-way transfer and conversion of medical analyzes" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamLabFeatures" defaultMessage="Key features" />:</p>
                    <p className="card-text card-text-list">
                      <br /><span className="list-item"><FormattedMessage id="products.encryptedTransfer" defaultMessage="Encrypted bidirectional transfer (Laboratory - Doctors)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_1" defaultMessage="Automatic files conversion according to doctors' software" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_2" defaultMessage="Real-time notifications (new result, transfer error, etc.)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_3" defaultMessage="Automatic printing of results (alternative to fax)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_4" defaultMessage="Optimization of HL7 files (norms, translations, etc.)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_5" defaultMessage="Possibility to automatically merge additional files to the results" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_6" defaultMessage="Cross-platform solution (Windows, Mac, Linux)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_7" defaultMessage="Intuitive and customizable management console" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_8" defaultMessage="Decreasing unit price according to the volume of analyzes" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_9" defaultMessage="Low maintenance and hotline costs" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_10" defaultMessage="Inter-laboratory transfers and conversions" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_11" defaultMessage="Possibility to send results directly to patients (eMail / SMS)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_12" defaultMessage="Possibility of sending epidemiological statistics to governments (SARS-CoV-2, etc.)" /></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row standalone-row align-items-center no-gutters">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInLeft image">
                    <GatsbyImage
                      className="img"
                      image={data.img3.childImageSharp.gatsbyImageData}
                      alt="covid-image" />
                  </div>
                </div>
                <div className="col-lg-6 stand-img-des">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.covid" defaultMessage="Medical registration" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.covidTitle" defaultMessage="Online registration for medical examinations / laboartory tests" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.covid_1" defaultMessage="Web application that allows individual users to make an appointment for a doctor or laboratory." /> </p>
                    <p className="card-text mt-1"><FormattedMessage id="products.covid_2" defaultMessage="It also gives access to companies like hotel to book appointments for their clients or make examinations locally in cooperation with laboratory." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.covid_3" defaultMessage="Application has live integration with laboratory management system." /></p>
                  </div>
                </div>
              </div>
              <div className="row standalone-row align-items-center no-gutters display-reverse">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInRight text-center image">
                    <GatsbyImage
                      className="img"
                      image={data.img4.childImageSharp.gatsbyImageData}
                      alt="briefcase-icon" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.invoicing" defaultMessage="StreamVOICE" /></p>
                    <h2 className="heading-text text-center text-white"><FormattedMessage id="products.invoicingTitle" defaultMessage="External invoice management module" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamLabFeatures" defaultMessage="Key features" />:</p>
                    <p className="card-text card-text-list">
                    <br /><span className="list-item"><FormattedMessage id="products.invoicing_1" defaultMessage="External module that seamlessly integrates into an existing LIS" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_2" defaultMessage="Intuitive web interface with customizable corporate design" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_3" defaultMessage="Customizable dashboard (Business Intelligence)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_4" defaultMessage="Compatible with Medidata Network (xml 4.5 format / QR invoice)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_5" defaultMessage="Ability to manage several departments with different RCC numbers" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_6" defaultMessage="Intuitive management of health insurance notifications (multi-user)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_7" defaultMessage="Generation of invoices in PDF with QR code" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_8" defaultMessage="Management of VAT and discounts at position level" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_9" defaultMessage="Generation of personalized invoices with the possibility of paper printing or electronic transmission (Medidata, secure E-Mail)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_10" defaultMessage="Generation of grouped invoices with the possibility of managing discounts and VAT at position level" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_11" defaultMessage="Watermark management on invoices (patient copy, ...)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_12" defaultMessage="Editing invoices (adding/deleting positions or free lines)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_13" defaultMessage="History of analysis tariffs as well as tariff positions (OFAS, OFSP, ...)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_14" defaultMessage="Proactive management of potential billing errors" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_15" defaultMessage="Fully compliant with OFAS business rules" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_16" defaultMessage="Multiple bank accounts management with automatic reconciliation of payments" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_17" defaultMessage="Management of invoice reminders and litigation" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.invoicing_18" defaultMessage="Automated management of patient invoice copies" /></span></p>
                  </div>
                </div>
              </div>
              <div className="row standalone-row align-items-center no-gutters">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInLeft image">
                    <GatsbyImage
                      className="img"
                      image={data.img5.childImageSharp.gatsbyImageData}
                      alt="covid-image" />
                  </div>
                </div>
                <div className="col-lg-6 stand-img-des">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.concentrator" defaultMessage="Lab concentrator" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.concentratorTitle" defaultMessage="Single software or hardware appliance to manage any laboratory devices" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamLabFeatures" defaultMessage="Key features" />:</p>
                    <p className="card-text card-text-list">
                    <br /><span className="list-item"><FormattedMessage id="products.concentrator_1" defaultMessage="Plug and play effortless portable solution" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_3" defaultMessage="An ever-growing instrument library" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_4" defaultMessage="Supports machines with direct connection like ASTM as well as those not connected (integration of files : XML, XLSX, CSV, HL7, ...)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_5" defaultMessage="Multiple LIS input and output protocols" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_6" defaultMessage="Easily customizable, it can adapt to unconventional architecture" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_7" defaultMessage="Great flexibility : switching analyzes on different instruments without modification in the LIS" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_8" defaultMessage="Completely independent, this module can be installed in addition to an existing installation when adding a new instrument or for the restructuring of part of a laboratory" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.concentrator_9" defaultMessage="Effective support when adding a new instrument" /></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Products
